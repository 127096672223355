@font-face {
    font-family: "Vazirmatn-Bold";
    src: local("Vazirmatn-Bold"),
        url("../fonts/Vazirmatn-Bold.ttf") format("truetype");
}

@font-face {
    font-family: "Vazirmatn";
    src: local("Vazirmatn-Regular"),
        url("../fonts/Vazirmatn-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "NokaTrial-Bold";
    src: local("NokaTrial-Bold"),
        url("../fonts/NokaTrial-Bold-BF63bccdec13b50.otf") format("truetype");
}

@font-face {
    font-family: "NokaTrial-SemiBold";
    src: local("NokaTrial-SemiBold"),
        url("../fonts/NokaTrial-Semibold-BF63bccdec16a7a.otf") format("truetype");
}


@font-face {
    font-family: "ReadexPro";
    src: local("ReadexPro-Bold"),
        url("../fonts/ReadexPro-Bold.ttf") format("truetype");
}


@media (min-width: 992px) {
    .sideLeft {
        clip-path: polygon(0 0, 100% 0, calc(100% - 5px) 100%, 0 100%)
    }

    .sideRight {
        clip-path: polygon(calc(10vw + 5px) 0, 100% 0, 100% 100%, 0 100%)
    }

    .sideLeft2 {
        clip-path: polygon(0 0, 90% 0, 100% 100%, 5px 100%);
    }

    .sideRight2 {
        clip-path: polygon(0 0, calc(90vw - 5px) 0, calc(90vw - 5px) 100%, 0 100%);
    }

    .sideLeft3 {
        clip-path: polygon(0 0, 100% 0, 90% 100%, 5px 100%);
    }

    .sideRight3 {
        clip-path: polygon(0 0, calc(90vw - 5px) 0, calc(90vw - 5px) 100%, 0 100%);
    }

    .sideLeft4 {
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%)
    }

    .sideRight4 {
        clip-path: polygon(100% 0, 0 0, 100% calc(90vw - 5px))
    }

}

.editor-container {
    border-radius: 2px;
    color: #000;
    position: relative;
    line-height: 20px;
    font-weight: 400;
    text-align: left;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  
  .editor-inner {
    background: #fff;
    position: relative;
  }
  
  .editor-input {
    min-height: 150px;
    resize: none;
    font-size: 15px;
    caret-color: rgb(5, 5, 5);
    position: relative;
    tab-size: 1;
    outline: 0;
    padding: 15px 10px;
    caret-color: #444;
  }
  
  .editor-placeholder {
    color: #999;
    overflow: hidden;
    position: absolute;
    text-overflow: ellipsis;
    top: 15px;
    left: 10px;
    font-size: 15px;
    user-select: none;
    display: inline-block;
    pointer-events: none;
  }
  
  .editor-text-bold {
    font-weight: bold;
  }
  
  .editor-text-italic {
    font-style: italic;
  }
  
  .editor-text-underline {
    text-decoration: underline;
  }
  
  .editor-text-strikethrough {
    text-decoration: line-through;
  }
  
  .editor-text-underlineStrikethrough {
    text-decoration: underline line-through;
  }
  
  .editor-text-code {
    background-color: rgb(240, 242, 245);
    padding: 1px 0.25rem;
    font-family: Menlo, Consolas, Monaco, monospace;
    font-size: 94%;
  }
  
  .editor-link {
    color: rgb(33, 111, 219);
    text-decoration: none;
  }
  
  .tree-view-output {
    display: block;
    background: #222;
    color: #fff;
    padding: 5px;
    font-size: 12px;
    white-space: pre-wrap;
    margin: 1px auto 10px auto;
    max-height: 250px;
    position: relative;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    overflow: auto;
    line-height: 14px;
  }
  
  .editor-code {
    background-color: rgb(240, 242, 245);
    font-family: Menlo, Consolas, Monaco, monospace;
    display: block;
    padding: 8px 8px 8px 52px;
    line-height: 1.53;
    font-size: 13px;
    margin: 0;
    margin-top: 8px;
    margin-bottom: 8px;
    tab-size: 2;
    /* white-space: pre; */
    overflow-x: auto;
    position: relative;
  }
  
  .editor-code:before {
    content: attr(data-gutter);
    position: absolute;
    background-color: #eee;
    left: 0;
    top: 0;
    border-right: 1px solid #ccc;
    padding: 8px;
    color: #777;
    white-space: pre-wrap;
    text-align: right;
    min-width: 25px;
  }
  .editor-code:after {
    content: attr(data-highlight-language);
    top: 0;
    right: 3px;
    padding: 3px;
    font-size: 10px;
    text-transform: uppercase;
    position: absolute;
    color: rgba(0, 0, 0, 0.5);
  }
  
  .editor-tokenComment {
    color: slategray;
  }
  
  .editor-tokenPunctuation {
    color: #999;
  }
  
  .editor-tokenProperty {
    color: #905;
  }
  
  .editor-tokenSelector {
    color: #690;
  }
  
  .editor-tokenOperator {
    color: #9a6e3a;
  }
  
  .editor-tokenAttr {
    color: #07a;
  }
  
  .editor-tokenVariable {
    color: #e90;
  }
  
  .editor-tokenFunction {
    color: #dd4a68;
  }
  
  .editor-paragraph {
    margin: 0;
    margin-bottom: 8px;
    position: relative;
  }
  
  .editor-paragraph:last-child {
    margin-bottom: 0;
  }
  
  .editor-heading-h1 {
    font-size: 24px;
    color: rgb(5, 5, 5);
    font-weight: 400;
    margin: 0;
    margin-bottom: 12px;
    padding: 0;
  }
  
  .editor-heading-h2 {
    font-size: 15px;
    color: rgb(101, 103, 107);
    font-weight: 700;
    margin: 0;
    margin-top: 10px;
    padding: 0;
    text-transform: uppercase;
  }
  
  .editor-quote {
    margin: 0;
    margin-left: 20px;
    font-size: 15px;
    color: rgb(101, 103, 107);
    border-left-color: rgb(206, 208, 212);
    border-left-width: 4px;
    border-left-style: solid;
    padding-left: 16px;
  }
  
  .editor-list-ol {
    padding: 0;
    margin: 0;
    margin-left: 16px;
  }
  
  .editor-list-ul {
    padding: 0;
    margin: 0;
    margin-left: 16px;
  }
  
  .editor-listitem {
    margin: 8px 32px 8px 32px;
  }
  
  .editor-nested-listitem {
    list-style-type: none;
  }

.miniCarIcon {
    transition: all .2s ease-in-out
}

.miniCarIcon:hover {
    transform: scale(1.1);
}

.video {
    aspect-ratio: 16 / 9;
    width: 100%;
    max-width: 560px;
}

.colorEasycar {
    color: #338e40;
}

.colorWhite {
    color: #FFF;
}